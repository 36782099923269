var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "RankList_wrapper" },
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "left" },
          on: { click: function($event) {}, "tab-click": _vm.handleClick },
          model: {
            value: _vm.tabName,
            callback: function($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "导购员排行", name: "1" } },
            [
              _c(
                "div",
                { staticClass: "select_wrap" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "mr10",
                      attrs: { placeholder: "请选择指标纬度" },
                      model: {
                        value: _vm.KpiUserInfo.type,
                        callback: function($$v) {
                          _vm.$set(_vm.KpiUserInfo, "type", $$v)
                        },
                        expression: "KpiUserInfo.type"
                      }
                    },
                    _vm._l(_vm.staffTypeOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  ),
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "value-format": "yyyy-MM-dd",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.KpiUserInfo.timeRange,
                      callback: function($$v) {
                        _vm.$set(_vm.KpiUserInfo, "timeRange", $$v)
                      },
                      expression: "KpiUserInfo.timeRange"
                    }
                  }),
                  _c("el-input", {
                    staticClass: "ml10",
                    staticStyle: { width: "200px" },
                    attrs: { clearable: true, placeholder: "请输入手机号搜索" },
                    model: {
                      value: _vm.KpiUserInfo.mobile,
                      callback: function($$v) {
                        _vm.$set(_vm.KpiUserInfo, "mobile", $$v)
                      },
                      expression: "KpiUserInfo.mobile"
                    }
                  }),
                  _c("el-input", {
                    staticClass: "ml10",
                    staticStyle: { width: "200px" },
                    attrs: { clearable: true, placeholder: "请输入姓名搜索" },
                    model: {
                      value: _vm.KpiUserInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.KpiUserInfo, "name", $$v)
                      },
                      expression: "KpiUserInfo.name"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.searchPage }
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "primary" },
                      on: { click: _vm.exportKpiUserData }
                    },
                    [_vm._v("导出表格")]
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticClass: "secondsKill_table",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.KpiUserList, border: "", stripe: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "rank", label: "排名" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "userId", label: "用户ID" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "姓名" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "mobile", label: "手机号码" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "brandName", label: "所属品牌" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "shopName", label: "所属门店" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "createTime", label: "注册时间" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "score", label: "数据" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tab-page" },
                [
                  _c("div"),
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.KpiUserInfo.page,
                      "page-size": 10,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.KpiUserTotal
                    },
                    on: { "current-change": _vm.handleCurrentChange }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "品牌排行", name: "2" } },
            [
              _c(
                "div",
                { staticClass: "select_wrap" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "mr10",
                      attrs: { placeholder: "请选择指标纬度" },
                      model: {
                        value: _vm.BrandInfo.type,
                        callback: function($$v) {
                          _vm.$set(_vm.BrandInfo, "type", $$v)
                        },
                        expression: "BrandInfo.type"
                      }
                    },
                    _vm._l(_vm.bonusTypeOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  ),
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.BrandInfo.timeRange,
                      callback: function($$v) {
                        _vm.$set(_vm.BrandInfo, "timeRange", $$v)
                      },
                      expression: "BrandInfo.timeRange"
                    }
                  }),
                  _c("el-input", {
                    staticClass: "ml10",
                    staticStyle: { width: "200px" },
                    attrs: { clearable: true, placeholder: "请输入品牌名称" },
                    model: {
                      value: _vm.BrandInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.BrandInfo, "name", $$v)
                      },
                      expression: "BrandInfo.name"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.searchPage2 }
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "primary" },
                      on: { click: _vm.getExportBrandRankList }
                    },
                    [_vm._v("导出表格")]
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticClass: "secondsKill_table",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.BrandList, border: "", stripe: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "rank", label: "排名" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "brandId", label: "品牌ID" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "brandLogo", label: "品牌LOGO" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: scope.row.brandLogo,
                                  expression: "scope.row.brandLogo"
                                }
                              ],
                              staticStyle: {
                                display: "block",
                                width: "50px",
                                height: "50px",
                                margin: "0 10px 0 0"
                              },
                              attrs: { src: scope.row.brandLogo }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "品牌名称" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "brandCatName", label: "所属军团" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "score", label: "数据" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tab-page" },
                [
                  _c("div"),
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.BrandInfo.page,
                      "page-size": 10,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.BrandTotal
                    },
                    on: { "current-change": _vm.handleCurrentChange2 }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }