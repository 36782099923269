<template>
  <div class="RankList_wrapper">
    <el-tabs tab-position="left" v-model="tabName" @click @tab-click="handleClick">
      <el-tab-pane label="导购员排行" name="1">
        <div class="select_wrap">
          <el-select v-model="KpiUserInfo.type" placeholder="请选择指标纬度" class="mr10">
            <el-option
              v-for="item in staffTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-date-picker
            v-model="KpiUserInfo.timeRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
          <el-input
            class="ml10"
            :clearable="true"
            placeholder="请输入手机号搜索"
            v-model="KpiUserInfo.mobile"
            style="width:200px"
          ></el-input>
          <el-input
            class="ml10"
            :clearable="true"
            placeholder="请输入姓名搜索"
            v-model="KpiUserInfo.name"
            style="width:200px"
          ></el-input>
          <el-button
            type="primary"
            class="ml10"
            icon="el-icon-search"
            @click="searchPage"
          >搜索</el-button>
          <el-button type="primary" class="ml10" @click="exportKpiUserData">导出表格</el-button>
        </div>
        <el-table class="secondsKill_table" :data="KpiUserList" border stripe style="width: 100%">
          <el-table-column prop="rank" label="排名"></el-table-column>
          <el-table-column prop="userId" label="用户ID"></el-table-column>
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="mobile" label="手机号码"></el-table-column>
          <el-table-column prop="brandName" label="所属品牌"></el-table-column>
          <el-table-column prop="shopName" label="所属门店"></el-table-column>
          <el-table-column prop="createTime" label="注册时间"></el-table-column>
          <el-table-column prop="score" label="数据"></el-table-column>
        </el-table>
        <div class="tab-page">
          <div></div>
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="KpiUserInfo.page"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="KpiUserTotal"
          ></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="品牌排行" name="2">
        <div class="select_wrap">
          <el-select v-model="BrandInfo.type" placeholder="请选择指标纬度" class="mr10">
            <el-option
              v-for="item in bonusTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-date-picker
            v-model="BrandInfo.timeRange"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
          <el-input
            class="ml10"
            :clearable="true"
            placeholder="请输入品牌名称"
            v-model="BrandInfo.name"
            style="width:200px"
          ></el-input>
          <el-button
            type="primary"
            class="ml10"
            icon="el-icon-search"
            @click="searchPage2"
          >搜索</el-button>
          <el-button type="primary" class="ml10" @click="getExportBrandRankList">导出表格</el-button>
        </div>
        <el-table class="secondsKill_table" :data="BrandList" border stripe style="width: 100%">
          <el-table-column prop="rank" label="排名"></el-table-column>
          <el-table-column prop="brandId" label="品牌ID"></el-table-column>
          <el-table-column prop="brandLogo" label="品牌LOGO">
            <template slot-scope="scope">
              <img
                v-show="scope.row.brandLogo"
                :src="scope.row.brandLogo"
                style="display:block;width:50px;height:50px;margin:0 10px 0 0;"
              />
            </template>
          </el-table-column>
          <el-table-column prop="name" label="品牌名称"></el-table-column>
          <el-table-column prop="brandCatName" label="所属军团"></el-table-column>
          <el-table-column prop="score" label="数据"></el-table-column>
        </el-table>
        <div class="tab-page">
          <div></div>
          <el-pagination
            background
            @current-change="handleCurrentChange2"
            :current-page="BrandInfo.page"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="BrandTotal"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 主页面 -->
  </div>
</template>

<script>
import GuideAjax from "@/utils/https/modules/Guide.request.js";
export default {
  name: "RankList", //
  components: {},
  props: {
    activityID: String,
  },
  data() {
    return {
      nodata: "",
      nobool: false,
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      typeOptions: [
        { value: "act", label: "活动负责人" },
        { value: "brand", label: "品牌负责人" },
        { value: "staff", label: "导购员" },
        // { value: "finance", label: "财务负责人" }
      ],
      KpiUserInfo: {
        actId: 0,
        endTime: "",
        mobile: "",
        name: "",
        page: 1,
        size: 10,
        startTime: "",
        type: "view",
        timeRange: [],
      },
      KpiUserList: [],
      KpiUserTotal: 0,
      BrandInfo: {
        actId: 0,
        endTime: "",
        name: "",
        page: 1,
        size: 10,
        startTime: "",
        type: "view",
        timeRange: [],
      },
      BrandList: [],
      BrandTotal: 0,
      tabName: "1",
      staffTypeOptions: [
        { value: "view", label: "浏览" },
        { value: "fission", label: "裂变" },
        { value: "enrollment", label: "报名" },
        { value: "card", label: "售券" },
        { value: "appointment", label: "直播预约" },
        { value: "live_view", label: "进直播间客户" },
        { value: "live_order_cus", label: "直播下订" },
        { value: "live_self_order", label: "直播自签单" },
        { value: "live_other_order", label: "直播贡献单" },
        { value: "live_self_order_change", label: "直播自签转单" },
        { value: "live_other_order_change", label: "直播贡献转单" },
        { value: "process_self_order", label: "过程自签单" },
        { value: "process_devote_order", label: "过程贡献单" },
        { value: "seckill_user", label: "秒杀客户" },
        { value: "thumb", label: "集赞客户" },
      ],
      bonusTypeOptions: [
        { value: "view", label: "浏览" },
        { value: "fission", label: "裂变" },
        { value: "enrollment", label: "报名" },
        { value: "card", label: "售券" },
        { value: "appointment", label: "直播预约" },
        { value: "live_view", label: "进直播间客户" },
        { value: "live_order_cus", label: "直播下订" },
        { value: "live_self_order", label: "直播自签单" },
        { value: "live_other_order", label: "直播贡献单" },
        { value: "live_self_order_change", label: "直播自签转单" },
        { value: "live_other_order_change", label: "直播贡献转单" },
        { value: "process_self_order", label: "过程自签单" },
        { value: "process_devote_order", label: "过程贡献单" },
        { value: "seckill_user", label: "秒杀客户" },
        { value: "thumb", label: "集赞客户" },
        { value: "order_sign_num", label: "签单数量" },
        { value: "score", label: "积分" },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    //切换tab方法
    handleClick() {
      if (this.tabName == "1") {
        this.KpiUserInfo.page = 1;
        this.getQueryKpiUserRankList();
      } else if (this.tabName == "2") {
        this.BrandInfo.page = 1;
        this.getQueryBrandRankList();
      }
    },
    // 查询导购员排行榜页码请求
    handleCurrentChange(val) {
      this.KpiUserInfo.page = val;
      this.getQueryKpiUserRankList();
    },
    searchPage() {
      this.KpiUserInfo.page = 1;
      this.getQueryKpiUserRankList();
    },
    // 查询品牌排行榜页码请求
    handleCurrentChange2(val) {
      this.BrandInfo.page = val;
      this.getQueryBrandRankList();
    },
    searchPage2() {
      this.BrandInfo.page = 1;
      this.getQueryBrandRankList();
    },
    // 查询导购员排行榜
    async getQueryKpiUserRankList() {
      try {
        this.KpiUserInfo.actId = this.activityID;
        let act = this.KpiUserInfo;
        if (act.timeRange && act.timeRange.length > 0) {
          act.startTime = act.timeRange[0] ? act.timeRange[0] + ' ' + '00:00:00' : '';
          act.endTime = act.timeRange[1] ? act.timeRange[1] + ' ' + '23:59:59' : '';
          // delete act.timeRange;
        } else {
          act.startTime = '';
          act.endTime = '';
        }
        const {
          data: { list, total },
        } = await GuideAjax.getQueryKpiUserRankList(act);
        this.KpiUserList = list;
        this.KpiUserTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出导购员排行榜
    async exportKpiUserData() {
      try {
        const data = await GuideAjax.getExportKpiUserRankList(this.KpiUserInfo);
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "导购员排行榜.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 查询品牌排行榜
    async getQueryBrandRankList() {
      try {
        this.BrandInfo.actId = this.activityID;
        let act = this.BrandInfo;
        if (act.timeRange && act.timeRange.length > 0) {
          act.startTime = act.timeRange[0];
          act.endTime = act.timeRange[1];
          // delete act.timeRange;
        }
        const {
          data: { list, total },
        } = await GuideAjax.getQueryBrandRankList(act);
        this.BrandList = list;
        this.BrandTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出品牌排行榜
    async getExportBrandRankList() {
      try {
        const data = await GuideAjax.getExportBrandRankList(this.BrandInfo);
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "品牌排行榜.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
  created() {
    this.getQueryKpiUserRankList();
    this.getQueryBrandRankList();
  },
  mounted() {},
};
</script>

<style lang="scss">
.RankList_wrapper {
  .select_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .secondsKill_table {
    margin-top: 20px;
    .el-table__body {
      .cell {
        span {
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .tab-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
  }
}
</style>
